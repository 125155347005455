import Utils from "./Utils";

class WindowUtils {
    
    constructor() {
        this.w = {
            h: window.innerHeight,
            w: window.innerWidth
        };

        this.BREAKPOINTS = {
            XS: { NAME: "xs", MIN: 0,    MAX: 767  },
            SM: { NAME: "sm", MIN: 768,  MAX: 979  },
            MD: { NAME: "md", MIN: 980,  MAX: 1199 },
            LG: { NAME: "lg", MIN: 1200, MAX: 1399 },
            XL: { NAME: "xl", MIN: 1400, MAX: null }
        };

        this.updateWindowH();
        this.updateWindowW();
        this.updateVh();

        // window.addEventListener('resize', Utils.debounce(() => { this.onResize() }), { passive: true });
        // window.addEventListener('scroll', Utils.debounce(() => { this.onScroll() }), { passive: true });
    }

    isTouch () {
        return 'ontouchstart' in document.documentElement;
    }

    isMobile () {
        return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    }
    
    isXS () {
        return window.innerWidth <= this.BREAKPOINTS.XS.MAX;
    }

    isSM () {
        return window.innerWidth >= this.BREAKPOINTS.SM.MIN && window.innerWidth <= this.BREAKPOINTS.SM.MAX;
    }
    
    isMD () {
        return window.innerWidth >= this.BREAKPOINTS.MD.MIN && window.innerWidth <= this.BREAKPOINTS.MD.MAX;
    }

    isLG () {
        return window.innerWidth >= this.BREAKPOINTS.LG.MIN && window.innerWidth <= this.BREAKPOINTS.LG.MAX;
    }

    isXL () {
        return window.innerWidth >= this.BREAKPOINTS.XL.MIN;
    }
    
    isTablet () {
        return isSM() || isMD();
    }

    isDesktop () {
        return isLG() || isXL();
    }

    updateWindowH () {
        this.w.h = window.innerHeight;
    }

    updateWindowW () {
        this.w.w = window.innerWidth;
    }

    updateVh () {
        var vh = this.w.h * 0.01 + "px";
        document.documentElement.style.setProperty('--vh', vh);
    }

    onResize () {
        this.updateWindowH();
        this.updateWindowW();
        this.updateVh();
    }
       
}

export default WindowUtils;