import anime, { stagger } from 'animejs';
import LocomotiveScroll from 'locomotive-scroll';

import WindowUtils from './utils/WindowUtils';
import Utils from "./utils/Utils";
import Type8 from './utils/Type8';

import Carousel from "./Carousel";

import GridDebug from './utils/GridDebug';
new GridDebug(12,0);

const WU = new WindowUtils();

let scroll;

/* ------------------------------------ */
/* ------ Locomotive scroll init ------ */
/* ------------------------------------ */
const createScroll = () => {
    let elt = document.querySelector('[data-scroll-container]');

    scroll = new LocomotiveScroll({
        el: elt,
        smooth: true,
        getSpeed: true,
        getDirection: true,
        gestureDirection: 'vertical'
    });

    scroll.stop();
    
    scroll.on('call', (value, way, obj) => {
        let calls = (typeof value === 'string') ? [value] : value;
        scrollAnimations(calls, way, obj);
    });

    let s;
    let coverIntro = document.querySelector('.imgIntro');
    scroll.on('scroll', (instance) => {
        s = instance.scroll.y;
        
        if (instance.direction == 'down' && s > WU.w.h) {
            document.querySelector('.mainTitle').classList.add('hidden');
        } else {
            document.querySelector('.mainTitle').classList.remove('hidden');
        }

        if (typeof instance.currentElements['intro'] === 'object') {
            let data = instance.currentElements['intro'];
            let progress = data.progress;

            if (progress >= 0 && progress <= 1) {
                anime.set(coverIntro, {
                    scale: progress
                });
            }
        }
    });

    return scroll;
};

// Scroll animations // data-scroll-call
const scrollAnimations = (calls, way, obj) => {
    calls.forEach(call => {
        if (call === 'xxxxxxx') {
            if (way === 'enter') {
                // TODO
            } else {
                // TODO
            }
        }
    });
};

// Load image async
const loadImages = (parent = document) => {
    parent.querySelectorAll('.imageRender:not(.imageRender--loaded)').forEach((img) => {
        let url = img.getAttribute('data-url');
        let cover = img.querySelector('.imageRender_cover');

        Utils.loadImage(url).then(() => {
            cover.style.backgroundImage = 'url(' + url + ')';
            img.classList.add('imageRender--loaded');
        });
    });
};

// Scroll to top
const scrollTop = (force) => {
    if (!scroll) return;
    if (force) {
        window.scrollTo(0,0);
        scroll.scrollTo(0, { duration: 0, disableLerp: true });
    } else {
        scroll.scrollTo(0, { duration: 1000, easing: [0.90, 0.00, 0.00, 1.00] });
    }
};

// Scroll to element
const scrollTo = (elt, offset = 0) => {
    scroll.scrollTo(elt, { duration: 1000, easing: [0.90, 0.00, 0.00, 1.00], offset: offset });
};

// JS CUSTOM HERE
const page = () => {

    scroll.stop();

    new Type8();
    loadImages();

    setTimeout(() => {
        document.querySelector('.mainTitle').classList.remove('explode');
        document.querySelector('.button--ap').classList.add('collapsed');
        document.querySelector('.button--form').classList.remove('hidden');
        document.querySelector('.button--form').classList.remove('hidden');
        document.querySelector('.header_link').classList.add('visible');
        document.querySelector('.header_arrow').classList.add('visible');
        document.documentElement.classList.remove('is-locked');
        scroll.start();
        scroll.update();
    }, 2500);

    document.querySelectorAll('[data-scrollto]').forEach((link) => {
        link.addEventListener('click', (e) => {
            e.preventDefault();
            let attr = link.getAttribute('data-scrollto');
            let elt;
            if (attr) {
                elt = document.querySelector(attr);
            }
            if (elt) {
                scrollTo(elt);
            }
        });
    });

    document.querySelector('.form > form').addEventListener('submit', function (event) {
        var data = this;
        fetch(data.getAttribute('action'), {
            method: data.getAttribute('method'),
            body: new FormData(data)
        }).then(res=>res.text())
        .then(function (data) {
            document.querySelector('.form').innerHTML = data;
        });
        event.preventDefault();
    });


    if (document.querySelector('.imgQuote.carousel')) {
        new Carousel('.imgQuote.carousel');
    }
};

const initPage = () => {
    createScroll();
    scrollTop(true);
    page();
};

const hideLoading = () => {
    setTimeout(() => {
        document.querySelector('.loadingScreen').classList.remove('visible');
    }, 500);
};

(function () {
    Utils.preloadHTMLImages(document.body.innerHTML).then(() => {
        initPage();
        hideLoading();
    });
})();